// import $ from 'jquery';
// window.jQuery = $;
// window.$ = $;

// Styles SCSS
import "../sass/home.scss";

// LazyLoad
import lazyLoadInit from "./lazyload-init";
lazyLoadInit();

import "jquery.marquee";

// pushbar
import Pushbar from "./utils/pushbar";

$(document).ready(() => {
  jQuery('.header__lang option:contains("Select Language")').text("langues");
  jQuery('.header__lang option:contains("English")').text("En");
  jQuery('.header__lang option:contains("French")').text("Fr");
  jQuery('.header__lang option:contains("Spanish")').text("Es");
  jQuery('.header__lang option:contains("Fr")').attr("selected", true);

  jQuery(".icon__plus").on("click", function () {
    jQuery(".jesuis").slideToggle();
  });

  $(".ticker").marquee({
    duration: 13000,
    delayBeforeStart: 0,
    direction: "left",
    gap: 50,
    duplicated: false,
    pauseOnHover: true,
  });

  new Pushbar({
    blur: false,
    overlay: false,
  });

  // jQuery('path').each(function() {
  // 	var clas = jQuery(this).attr('class');
  // 	var clas1 = clas.split(' ')[1];
  // 	jQuery(this).on('click', function() {
  // 		jQuery('.area').removeClass('active');
  // 		jQuery('.carte__container span').removeClass('active');
  // 		jQuery('.communes__item').removeClass('active');
  // 		jQuery(this).addClass('active');
  // 		jQuery('.' + clas1).addClass('active');
  // 	});
  // });

  $(".header__search").on("click", function () {
    $(".hide").toggleClass("visible");
  });
});
